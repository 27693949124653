// eslint-disable-next-line import/prefer-default-export
export const calculaDigitoMod11 = (dado: string): string => {
  let mult = 12;
  let dig; // eslint-disable-line
  let soma; // eslint-disable-line
  const numDig = 2;
  let i = 0;

  for (let n = 1; n <= numDig; n++) {
    soma = 0;
    mult = 2;
    for (i = dado.length - 1; i >= 0; i--) {
      soma += (mult * parseInt(dado.charAt(i))); // eslint-disable-line
      if (++mult > 12) mult = 2;
    }
    dig = ((soma * 10) % 11) % 10; // eslint-disable-line

    dado += (dig); // eslint-disable-line
  }
  return dado.substr(dado.length - numDig, numDig);
};
