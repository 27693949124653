<template>
  <div>
    <v-row>
      <v-col col='6'>
        <div>
          <strong>Relatório de impressão</strong>
        </div>
        <div>
          {{selectedValues.filter((v) => !v.blank).length}} documentos
        </div>
        <div v-if='saveMirrorValue'>
          Sequência inicial: {{firstSequence.toString().padStart(10, '0')}}-{{firstSequenceMod}}
        </div>
        <div v-if='saveMirrorValue'>
          Sequência final: {{getLastSequence()}}
        </div>
      </v-col>
      <v-col cols='3' class='mr-5'>
        <v-row class='mt-0'>
          <v-col>
            <v-btn class='mt-2' plain :color='showConfigs ? "primary" : "grey"' @click='showConfigs = !showConfigs'>
              <v-icon>mdi-cog</v-icon>
              {{ $t('CONFIGURATIONS') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-select
              v-model='printer'
              :items='printers'
              item-text='name'
              item-value='id'
              label='Impressora'
              :error='printers === ""'
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class='d-flex justify-end'>
        <v-btn
          color='primary'
          class='mr-4'
          @click='backStep()'
        >
          {{$t('PREVIOUS_STEP')}}
        </v-btn>
        <v-btn
          color='primary'
          class='mr-4'
          :loading='isMountPDF'
          :disabled='selectedValues.length === 0'
          @click='showConfirmModal'
        >
          Imprimir
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if='showConfigs' class='mr-1 ml-1 mb-2'>
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>Configuração de Folha</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.width'
                  type='number'
                  label='Largura'
                  suffix='mm'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.height'
                  type='number'
                  label='Altura'
                  suffix='mm'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginPaperLeft'
                  type='number'
                  label='Margem da Esquerda'
                  suffix='mm'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginPaperRight'
                  type='number'
                  label='Margem da Direita'
                  suffix='mm'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginPaperTop'
                  type='number'
                  label='Margem De Cima'
                  suffix='mm'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginPaperBottom'
                  type='number'
                  label='Margem de baixo'
                  suffix='mm'
                  required
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row v-if='showConfigs' class='mr-1 ml-1'>
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>Configuração de Documento</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.docPerPage'
                  type='number'
                  label='Documentos por página'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.maxPerLine'
                  type='number'
                  label='Máximo por linha'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginDocLeft'
                  type='number'
                  label='Margem Documento da Direita'
                  suffix='mm'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginDocBottom'
                  type='number'
                  label='Margem Documento embaixo'
                  suffix='mm'
                  required
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row class='mt-5 mr-1 ml-1'>
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>Configuração do Grid</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols='12' md='2'>
                <v-checkbox
                  v-model='grid.show'
                  label='Mostrar Grid em tela'
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='grid.width'
                  type='number'
                  label='Tamanho da coluna'
                  suffix='mm'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='grid.height'
                  type='number'
                  label='Tamanho da linha'
                  suffix='mm'
                  required
                />
              </v-col>
              <v-col cols='12' md='4'>
                <v-slider
                  v-model='grid.opacity'
                  label='Opacidade das linhas'
                  max='1'
                  min='0'
                  thumb-label='always'
                  step='0.1'
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row>
      <v-col col='12'>
        <v-sheet v-if='selectedTemplate !== ""'>
          <v-slide-group
            class='pa-4'
            show-arrows
          >
            <v-slide-item
              v-for='n in getSlideQtde'
              :key='n'
            >
              <div
                id='documentPage'
                :style='{
                  width: `${configLayout.width}mm`,
                  height: `${configLayout.height}mm`,
                  border: "2px dashed black !important",
                  "padding-top": `${configLayout.externalLeftMargin || 0}mm`,
                  "padding-left": `${configLayout.externalLeftMargin || 0}mm`,
                  "padding-right": `${configLayout.externalLeftMargin || 0}mm`,
                  "padding-bottom": `${configLayout.externalLeftMargin || 0}mm`
                }'
              >
                <div
                  class='grid-external-margin'
                  :style='{
                    "width": `${configLayout.width - ((configLayout.externalLeftMargin || 0) * 2)}mm`,
                    "height": `${configLayout.height - ((configLayout.externalBottomMargin || 0) * 2)}mm`,
                    "border": "1px solid black",
                    "top": "0px",
                    "left": "0px",
                    "position": "absolute",
                    "margin-top": `${configLayout.externalTopMargin || 0}mm`,
                    "margin-left": `${configLayout.externalLeftMargin || 0}mm`,
                    "margin-right": `${configLayout.externalRightMargin || 0}mm`,
                    "margin-bottom": `${configLayout.externalBottomMargin || 0}mm`
                  }'
                >
                  <template
                    v-for='(document, indexDocument) in getArrayFormat(n)'
                  >
                    <div
                      :id='document.name'
                      :key='document.name'
                      :style='{
                        "margin-top": `${configLayout.marginPaperTop}mm`,
                        "margin-left": `${ (indexDocument === 0 || newLine(indexDocument - 1)) ? configLayout.marginPaperLeft : configLayout.marginDocLeft}mm`,
                        "margin-bottom": `${configLayout.marginDocBottom}mm`,
                        "display": "inline-block",
                        "position": "relative",
                      }'
                    >
                      <Canvas
                        :id='`${document.name}_${document.cargo}`'
                        :values='document'
                        :template='template'
                        @isFrontAndBack='setIsFrontAndBack'
                        @paperLayout='setPaperLayout'
                        @pageLayout='setPageLayout'
                        @saveMirror='setSaveMirror'
                        @sizeLayout='setSizeLayout'
                        @fieldToSave='setFieldToSave'
                        @validationError='showError'
                      />
                    </div>
                    <div
                      v-if='newLine(indexDocument)'
                      :key='`${document.name}_${indexDocument}`'
                      :style='{
                        "flex-basis": "100%",
                        "height": 0,
                      }'
                    />
                  </template>
                </div>
                <div v-if='grid.show'>
                  <div
                    v-for='countRows in parseInt(configLayout.height / grid.height) - 1'
                    :key='countRows'
                    class='grid-rows'
                    :style='{
                      "width": `${configLayout.width}mm`,
                      "border": "1px solid black",
                      "opacity": `${parseFloat(grid.opacity)}`,
                      "top": `${grid.height * countRows}mm`,
                      "left": "0px",
                      "position": "absolute"
                    }'
                  />
                </div>

                <div v-if='grid.show'>
                  <div
                    v-for='countColumns in parseInt(configLayout.width / grid.width) - 1'
                    :key='countColumns'
                    class='grid-columns'
                    :style='{
                      "height": `100%`,
                      "border": "1px solid black",
                      "opacity": `${parseFloat(grid.opacity)}`,
                      "top": "0px",
                      "left": `${grid.width * countColumns}mm`,
                      "position": "absolute"
                    }'
                  />
                </div>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row justify='center'>
      <v-dialog
        v-model='dialog'
        fullscreen
        hide-overlay
        transition='dialog-bottom-transition'
      >
        <v-toolbar
          dark
          color='primary'
        >
          <v-btn
            icon
            dark
            @click='dialog = false'
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Configuração da impressora</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              color='secondary'
              text
              @click='dialog = false'
            >
              {{$t('SAVE')}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <Row>
              <v-col>
                <v-select
                  v-model='printer'
                  label='Tipo de papel (bandeja)'
                />
              </v-col>
            </Row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import axios from 'axios';
  import { calculaDigitoMod11 } from '../../../util/mod';
  import { base64ToArrayBuffer } from '../../../util/file';

  export default {
    name: 'TemplatePage',
    props: {
      selected: {
        type: Array,
        required: true,
      },
      template: {
        type: Object,
        required: true,
      },
    },
    mounted: function () {
      this.geValues();
      this.getFirstSequence();
      this.getAllPrinters();
      this.setPrintConfigByTemplate();
    },

    data: function () {
      return {
        selectedValues: [],
        dialog: false,
        printers: [],
        printer: '',
        isMountPDF: false,
        firstSequence: '',
        firstSequenceMod: '',
        isFrontAndBack: false,
        saveMirrorValue: false,
        fieldToSave: '',
        selectedTemplate: '',
        widthLayout: '',
        heightLayout: '',
        grid: {
          height: 5,
          width: 5,
          show: false,
          opacity: 0.5,
        },
        configLayout: {
          docPerPage: 8,
          marginPaperLeft: 17.92,
          marginPaperRight: 17.92,
          marginPaperTop: 11.67,
          marginPaperBottom: 11.67,
          marginDocLeft: 15,
          marginDocBottom: 11.67,
          maxPerLine: 4,
          externalLeftMargin: 0,
          externalRightMargin: 0,
          externalTopMargin: 0,
          externalBottomMargin: 0,
          width: 297,
          height: 218,
        },
        showConfigs: false,
      };
    },

    components: {
      Canvas: () => import('../components/Canvas/CNH/Canvas'),
    },

    computed: {
      getSlideQtde: function () {
        const qtdePage = Math.round(this.selectedValues.length / this.configLayout.docPerPage, 2);

        return qtdePage === 0 ? 1 : qtdePage;
      },
    },

    methods: {
      setPrintConfigByTemplate: function () {
        this.configLayout.docPerPage = this.template.printConfig?.documentsPerPage || 8;
        this.configLayout.maxPerLine = this.template.printConfig?.maxPerLine || 4;
        this.configLayout.marginDocLeft = this.template.printConfig?.marginDocRight || 15;
        this.configLayout.marginDocBottom = this.template.printConfig?.marginDocBottom || 15;
      },
      geValues: function () {
        this.selectedValues = this.selected;
        this.selectedTemplate = this.template;
      },
      setIsFrontAndBack: function (value) {
        this.isFrontAndBack = value;
      },
      setSaveMirror: function (value) {
        this.saveMirrorValue = value;
      },
      setFieldToSave: function (value) {
        this.fieldToSave = value;
      },
      setPageLayout: function (value) {
        this.configLayout.externalLeftMargin = value.externalLeftMargin;
        this.configLayout.externalRightMargin = value.externalRightMargin;
        this.configLayout.externalBottomMargin = value.externalBottomMargin;
        this.configLayout.externalTopMargin = value.externalTopMargin;
      },
      setSizeLayout: function (value) {
        this.widthLayout = value.width;
        this.heightLayout = value.height;
      },
      setPaperLayout: function (value) {
        if (!value) return;
        this.configLayout.width = value.width;
        this.configLayout.height = value.height;

        this.configLayout.marginPaperLeft = value.leftMargin;
        this.configLayout.marginPaperRight = value.rightMargin;
        this.configLayout.marginPaperTop = value.topMargin;
        this.configLayout.marginPaperBottom = value.bottomMargin;

        this.configLayout.marginDocLeft = value.documentLeftMargin;
        this.configLayout.marginDocBottom = value.documentBottomMargin;
      },
      mountPDF: async function () {
        if (this.printer === '') {
          Swal.fire({
            icon: 'warning',
            title: 'Impressora não selecionada',
            text: 'Você deve selecionar uma impressora antes de enviar o documento',
          });
          return;
        }

        try {
          this.isMountPDF = true;
          const backgroundList = Array.from(document.getElementsByClassName('background'));
          const gridColunsList = Array.from(document.getElementsByClassName('grid-columns'));
          const gridRowsList = Array.from(document.getElementsByClassName('grid-rows'));
          const externalGridList = Array.from(document.getElementsByClassName('grid-external-margin'));

          gridColunsList.forEach((columns) => {
            columns.remove();
          });

          gridRowsList.forEach((rows) => {
            rows.remove();
          });

          externalGridList.forEach((columns) => {
            columns.style.border = '';
          });

          backgroundList.forEach((background) => {
            background.remove();
          });

          const html = document.getElementById('documentPage');
          html.style.border = '';
          html.innerHTML = html.innerHTML.replaceAll('v-sheet', '');

          let finalHtml = `<html>
          <head><link href="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css" rel="stylesheet"></head>
          <body>
          ${html.outerHTML}
          </body>
          </html>
          `;

          finalHtml = finalHtml.replaceAll('transform:', '-webkit-transform:');
          console.log(finalHtml); /* eslint-disable-line no-console */

          axios({
            url: '/document/print',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              printerId: this.printer,
              htmlBase64: finalHtml,
              templateName: this.selectedTemplate.name,
            },
          }).then(({ data }) => {
            if (data && data.pdf) {
              const blob = new Blob([base64ToArrayBuffer(data.pdf)], { type: 'application/pdf' });
              const blobUrl = URL.createObjectURL(blob);

              const printWindow = window.open(blobUrl);
              printWindow.print();
            }
            this.isMountPDF = false;
            html.style.border = '2px dashed black';
            this.$emit('linkRenach', this.saveMirrorValue);
            this.$emit('fieldToSave', this.fieldToSave);
            this.$emit('next');
          }).catch((e) => {
            console.error(e); /* eslint-disable-line no-console */
            this.isMountPDF = false;
            this.printFailRetry();
          });
        } catch (e) {
          this.isMountPDF = false;
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      getAllPrinters: async function () {
        try {
          const result = await axios({
            url: '/printers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.printers = result.data.printers;

          if (this.printers.length === 1) {
            this.printer = this.printers[0].id;
          }
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      getArrayFormat: function (indexPage) {
        let firstIndex = 0;
        if (indexPage !== 1) firstIndex = indexPage + Number(this.configLayout.docPerPage) - 2;
        let lastIndex = Number(firstIndex) + Number(this.configLayout.docPerPage);

        if (lastIndex >= this.selectedValues.length) lastIndex = (this.selectedValues.length);

        const formatList = this.selectedValues.slice(firstIndex, lastIndex);

        return formatList;
      },
      backStep: function () {
        this.$emit('back');
      },
      base64ToImage: function (img) {
        if (img) {
          return `data:image/jpg;base64,${img}`;
        }
        return null;
      },
      newLine: function (index) {
        if (Number(this.configLayout.maxPerLine) === 1) {
          return true;
        }
        return (index + 1) / this.configLayout.maxPerLine === 1;
      },
      printFailRetry: function () {
        Swal.fire({
          icon: 'error',
          title: 'Falha na impressão',
          text: 'Houve uma falha de conexão com a impressora. Deseja tentar novamente?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.mountPDF();
          }
        });
      },
      showConfirmModal: function () {
        Swal.fire({
          icon: 'warning',
          text: 'Deseja confirmar operação?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.mountPDF();
            this.$emit('firstSequence', this.firstSequence);
          }
        });
      },
      getFirstSequence: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/first-sequence',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.firstSequence = result.data.firstSequence.sequence;
          this.firstSequenceMod = result.data.firstSequence.sequence_mod;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      getLastSequence: function () {
        const lastSequence = (this.firstSequence + this.selectedValues.length - 1).toString().padStart(10, '0');
        const lastSequenceMod = calculaDigitoMod11(lastSequence);

        return `${lastSequence}-${lastSequenceMod}`;
      },
      showError: function ({ title, text }) {
        Swal.fire({
          icon: 'error',
          title,
          text,
        });
      },
    },
  };
</script>

<style>
  .break {
    flex-basis: 100%;
    height: 0;
  }
</style>
